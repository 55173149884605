import React from 'react';
import Footer from 'modules/footer';
import Header from 'modules/header';

interface IProps {
    children: any;
}

const LayoutMain = ({ children }: IProps) => {
    return (
        <main className="layout-main">
            <Header />
            <section className="layout-main__container">{children}</section>
            <Footer showScrollButton />
        </main>
    );
};

export default LayoutMain;
