import Actions from 'api/actions';
import { updateCategories, updateRoutes } from 'store/helpers/update-store';
import {
    PATH_ADMIN,
    PATH_ADMIN_ASSOCIATIONS,
    PATH_ADMIN_FEEDBACK,
    PATH_ADMIN_PRICES_MONITORING,
    PATH_ADMIN_SETTINGS,
    PATH_ADMIN_STORAGES,
    PATH_ADMIN_USERS,
    PATH_ANY,
    PATH_AUTH,
    PATH_AUTH_DIFF_USER,
    PATH_CONFIGURATIONS_LIST,
    PATH_CONFIGURATIONS_LIST_ITEM,
    PATH_CONFIGURATIONS_PRICE_CS_GRAVITON,
    PATH_CONFIGURATION_HISTORY,
    PATH_EDUCATION,
    PATH_NEWS,
    PATH_PRODUCTS,
    PATH_PROFILE,
    PATH_PROJECTS,
    PATH_REGISTRATION,
    PATH_RESTORE,
} from 'router/constants';
import { ICategoriesData, IRoute, IRouteElement } from 'assets/ts/types';
import convertData from 'assets/ts/helpers/convert-data';
import getError from 'assets/ts/helpers/get-error';
import { ERROR_NO_DATA } from 'assets/ts/constants';

const actions = new Actions();
const ERROR_NO_DATA_CATEGORIES = `${ERROR_NO_DATA}: список категорий.`;
const outOfService = process.env.REACT_APP_OUT_OF_SERVICE === 'true';

const setRoutes = async (currentApp: string, loggedIn: boolean, showAssociationsPage: boolean = false, locationState?: any) => {
    let pages: string[] | undefined;

    const collectRoutes = async () => {
        const routes = await getRoutes(currentApp, loggedIn, showAssociationsPage, pages, locationState);
        if (routes?.length) {
            updateRoutes(routes);
        }
    };

    const fetchAccessiblePages = async () => {
        try {
            const result = await actions.fetchAccessiblePages(currentApp);
            if (result?.detail) {
                throw new Error(getError({ detail: result.detail }));
            }
            pages = result;
            if (pages) {
                await collectRoutes();
            }
        } catch (error) {
            const errorStr = (error as Error).message;
            errorStr && console.error(errorStr);
        }
    };

    if (loggedIn) {
        await fetchAccessiblePages();
    } else {
        await collectRoutes();
    }
};

const getRoutes = async (
    currentApp: string,
    loggedIn: boolean,
    showAssociationsPage: boolean,
    pages: string[] = [],
    locationState?: any
): Promise<IRoute[]> => {
    const isAdmin = checkAccess(PATH_ADMIN);
    const showList = checkAccess(PATH_CONFIGURATIONS_LIST);
    const showProducts = checkAccess(PATH_PRODUCTS);

    const locationFrom = locationState?.from?.pathname;
    const pathDefault = `/${getPathDefault(pages)}`;
    const defaultNavigation = { name: 'Navigate', to: pathDefault, replace: true };

    function getElement(element: IRouteElement, isPrivate: boolean = true) {
        if (outOfService) {
            if (element.name === 'Page404') {
                return element;
            } else {
                return { name: 'Navigate', to: '/404', replace: true };
            }
        } else {
            return loggedIn || !isPrivate ? element : { name: 'Navigate', to: PATH_AUTH, replace: true };
        }
    }

    function checkAccess(id: string) {
        return !!(pages.length && pages.includes(id));
    }

    const adminPages: IRoute[] = [
        {
            id: `${PATH_ADMIN}-${PATH_ADMIN_USERS}`,
            path: PATH_ADMIN_USERS,
            title: 'Пользователи',
            accessible: checkAccess(`${PATH_ADMIN}_${PATH_ADMIN_USERS}`),
            navAside: true,
            isActive: true,
            isPrivate: true,
            element: getElement(isAdmin ? { name: 'Users' } : defaultNavigation),
        },
        {
            id: `${PATH_ADMIN}-${PATH_ADMIN_SETTINGS}`,
            path: PATH_ADMIN_SETTINGS,
            title: 'Настройки коэффициентов',
            accessible: checkAccess(`${PATH_ADMIN}_${PATH_ADMIN_SETTINGS}`),
            navAside: true,
            isActive: true,
            isPrivate: true,
            element: getElement(isAdmin ? { name: 'AppSettings' } : defaultNavigation),
        },
        {
            id: `${PATH_ADMIN}-${PATH_ADMIN_ASSOCIATIONS}`,
            path: PATH_ADMIN_ASSOCIATIONS,
            title: 'Матрицы совместимости',
            accessible: checkAccess(`${PATH_ADMIN}_${PATH_ADMIN_ASSOCIATIONS}`),
            navAside: showAssociationsPage,
            isActive: true,
            isPrivate: true,
            element: getElement(isAdmin && showAssociationsPage ? { name: 'Associations' } : defaultNavigation),
        },
        {
            id: `${PATH_ADMIN}-${PATH_ADMIN_STORAGES}`,
            path: PATH_ADMIN_STORAGES,
            title: 'Реестр складов',
            accessible: checkAccess(`${PATH_ADMIN}_${PATH_ADMIN_STORAGES}`),
            navAside: true,
            isActive: true,
            isPrivate: true,
            element: getElement(isAdmin ? { name: 'Storages' } : defaultNavigation),
        },
        {
            id: `${PATH_ADMIN}-${PATH_ADMIN_PRICES_MONITORING}`,
            path: PATH_ADMIN_PRICES_MONITORING,
            title: 'Мониторинг расхождения цен',
            accessible: checkAccess(`${PATH_ADMIN}_${PATH_ADMIN_PRICES_MONITORING}`),
            navAside: true,
            isActive: true,
            isPrivate: true,
            element: getElement(isAdmin ? { name: 'PricesMonitoring' } : defaultNavigation),
        },
        {
            id: `${PATH_ADMIN}-${PATH_AUTH_DIFF_USER}`,
            path: PATH_AUTH_DIFF_USER,
            title: 'Авторизация под другим пользователем',
            navAside: false,
            isActive: true,
            isPrivate: true,
            accessible: checkAccess(`${PATH_ADMIN}_${PATH_AUTH_DIFF_USER}`),
            element: getElement(isAdmin ? { name: 'AuthDifferentUser' } : defaultNavigation),
        },
        {
            id: `${PATH_ADMIN}-${PATH_NEWS}`,
            path: PATH_NEWS,
            title: 'Новости. Админка',
            isPrivate: true,
            isActive: true,
            accessible: checkAccess(`${PATH_ADMIN}_${PATH_NEWS}`),
            navAside: true,
            element: getElement({ name: 'NewsAdmin' }),
        },
        {
            id: `${PATH_ADMIN}-${PATH_ADMIN_FEEDBACK}`,
            path: PATH_ADMIN_FEEDBACK,
            title: 'Обратная связь',
            navAside: true,
            isActive: true,
            isPrivate: true,
            accessible: checkAccess(`${PATH_ADMIN}_${PATH_ADMIN_FEEDBACK}`),
            element: getElement(isAdmin ? { name: 'FeedbackAdmin' } : defaultNavigation),
        },
        {
            id: `${PATH_ADMIN}-${PATH_EDUCATION}`,
            path: PATH_EDUCATION,
            title: 'Обучающие материалы. Админка',
            isPrivate: true,
            isActive: true,
            accessible: checkAccess(`${PATH_ADMIN}_${PATH_EDUCATION}`),
            navAside: true,
            element: getElement({ name: 'EducationAdmin' }),
        },
    ];

    const routes: IRoute[] = [
        {
            id: 'main',
            path: PATH_AUTH,
            title: 'Авторизация',
            isPrivate: false,
            isActive: true,
            accessible: true,
            navAside: false,
            element: outOfService
                ? { name: 'Navigate', to: '/404', replace: true }
                : !loggedIn
                ? { name: 'Auth' }
                : { name: 'Navigate', to: locationFrom || pathDefault, replace: true },
            children: [
                {
                    id: PATH_REGISTRATION,
                    path: PATH_REGISTRATION,
                    title: 'Регистрация',
                    isPrivate: false,
                    isActive: true,
                    accessible: true,
                    navAside: false,
                    element: getElement(defaultNavigation, false),
                    children: [
                        {
                            id: 'token',
                            path: ':token',
                            title: 'Регистрация',
                            isPrivate: false,
                            isActive: true,
                            accessible: true,
                            navAside: false,
                            element: getElement({ name: 'Registration' }, false),
                        },
                    ],
                },
                {
                    id: PATH_RESTORE,
                    path: PATH_RESTORE,
                    title: 'Сброс пароля',
                    isPrivate: false,
                    isActive: true,
                    accessible: true,
                    navAside: false,
                    element: getElement({ name: 'Restore' }, false),
                    children: [
                        {
                            id: 'token',
                            path: ':token',
                            title: 'Восстановление пароля',
                            isPrivate: false,
                            isActive: true,
                            accessible: true,
                            navAside: false,
                            element: getElement({ name: 'Restore' }, false),
                        },
                    ],
                },
                {
                    id: PATH_PROFILE,
                    path: PATH_PROFILE,
                    title: 'Личный кабинет',
                    isPrivate: true,
                    isActive: true,
                    accessible: checkAccess(PATH_PROFILE),
                    navAside: false,
                    element: getElement({ name: 'Profile' }),
                },
                {
                    id: PATH_NEWS,
                    path: PATH_NEWS,
                    title: 'Новости',
                    isPrivate: true,
                    isActive: true,
                    accessible: checkAccess(PATH_NEWS),
                    navAside: false,
                    element: getElement({ name: 'News' }),
                },
                {
                    id: PATH_CONFIGURATIONS_PRICE_CS_GRAVITON,
                    path: PATH_CONFIGURATIONS_PRICE_CS_GRAVITON,
                    title: 'Прайс КС Гравитон',
                    isPrivate: true,
                    isActive: true,
                    accessible: checkAccess(PATH_CONFIGURATIONS_PRICE_CS_GRAVITON),
                    navAside: true,
                    element: getElement({ name: 'ConfigurationsList', options: { priceCsGraviton: true } }),
                    children: [
                        {
                            id: PATH_CONFIGURATIONS_LIST_ITEM,
                            path: ':id',
                            title: 'Конфигуратор',
                            isPrivate: true,
                            isActive: true,
                            accessible: showList,
                            navAside: false,
                            element: getElement({ name: 'ConfiguratorForm', options: { priceCsGraviton: true } }),
                        },
                        {
                            id: `${PATH_CONFIGURATIONS_LIST_ITEM}-${PATH_CONFIGURATION_HISTORY}`,
                            path: `${PATH_CONFIGURATION_HISTORY}/:id`,
                            title: 'История изменений',
                            isPrivate: true,
                            isActive: true,
                            accessible: showList,
                            navAside: false,
                            element: getElement({ name: 'ConfigurationHistory', options: { priceCsGraviton: true } }),
                        },
                    ],
                },
                {
                    id: PATH_CONFIGURATIONS_LIST,
                    path: PATH_CONFIGURATIONS_LIST,
                    title: 'Реестр готовых сборок',
                    isPrivate: true,
                    isActive: true,
                    accessible: showList,
                    navAside: true,
                    element: getElement({ name: 'ConfigurationsList' }),
                    children: [
                        {
                            id: PATH_CONFIGURATIONS_LIST_ITEM,
                            path: ':id',
                            title: 'Конфигуратор',
                            isPrivate: true,
                            isActive: true,
                            accessible: showList,
                            navAside: false,
                            element: getElement({ name: 'ConfiguratorForm' }),
                        },
                        {
                            id: `${PATH_CONFIGURATIONS_LIST_ITEM}-${PATH_CONFIGURATION_HISTORY}`,
                            path: `${PATH_CONFIGURATION_HISTORY}/:id`,
                            title: 'История изменений',
                            isPrivate: true,
                            isActive: true,
                            accessible: showList,
                            navAside: false,
                            element: getElement({ name: 'ConfigurationHistory' }),
                        },
                    ],
                },
                {
                    id: PATH_PROJECTS,
                    path: PATH_PROJECTS,
                    title: 'Проекты',
                    isPrivate: true,
                    isActive: true,
                    accessible: checkAccess(PATH_PROJECTS),
                    navAside: true,
                    element: getElement({ name: 'Projects' }),
                },
                {
                    id: PATH_EDUCATION,
                    path: PATH_EDUCATION,
                    title: 'Обучающие материалы',
                    isPrivate: true,
                    isActive: true,
                    accessible: checkAccess(PATH_EDUCATION),
                    navAside: true,
                    element: getElement({ name: 'Education' }),
                },
                {
                    id: PATH_ADMIN,
                    path: PATH_ADMIN,
                    title: 'Админ панель',
                    isPrivate: true,
                    isActive: true,
                    accessible: isAdmin,
                    navAside: true,
                    element: getElement(isAdmin ? { name: 'AdminIndex', options: { pages: adminPages } } : defaultNavigation),
                    children: adminPages,
                },
            ],
        },
        {
            id: 'page-404',
            path: PATH_ANY,
            title: 'Страница 404',
            navAside: false,
            accessible: true,
            isPrivate: true,
            isActive: true,
            element: getElement({ name: 'Page404' }),
        },
    ];

    // получаем список категорий ТМЦ для вывода в меню
    if (loggedIn && showProducts) {
        const getCategories = async () => {
            return await actions
                .fetchCategories(currentApp)
                .then(result => {
                    const { categories, detail } = result;

                    if (detail) {
                        throw new Error(getError({ detail }));
                    }
                    if (!categories) {
                        throw new Error(ERROR_NO_DATA_CATEGORIES);
                    }
                    if (!categories.length) {
                        return routes;
                    }

                    const categoriesData: ICategoriesData = convertData('categories-data', result);
                    updateCategories(categoriesData);

                    const children: IRoute[] = [];
                    const productsNav: IRoute = {
                        id: PATH_PRODUCTS,
                        path: PATH_PRODUCTS,
                        title: 'Комплектующие',
                        isPrivate: true,
                        isActive: true,
                        accessible: showProducts,
                        navAside: true,
                        element: getElement(showProducts ? { name: 'ProductsIndex' } : defaultNavigation),
                    };

                    Object.entries(categoriesData).forEach(([cName, cData]) => {
                        const { title, isVirtual } = cData;
                        children.push({
                            id: `${PATH_PRODUCTS}-${cName}`,
                            path: cName,
                            title,
                            isPrivate: true,
                            isActive: true,
                            accessible: showProducts,
                            navAside: true,
                            element: getElement(showProducts ? { name: cName, type: 'products', options: { isVirtual } } : defaultNavigation),
                        });
                    });

                    productsNav.children = children;
                    routes[0].children?.push(productsNav);
                })
                .catch(error => {
                    const errorStr = (error as Error).message;
                    errorStr && console.error(errorStr);
                })
                .then(() => {
                    return routes;
                });
        };

        return await getCategories();
    }

    return new Promise(resolve => resolve(routes));
};

function getPathDefault(pages: string[]) {
    if (pages.length) {
        if (pages.includes(PATH_CONFIGURATIONS_LIST)) {
            return PATH_CONFIGURATIONS_LIST;
        }
        if (pages[0] === PATH_ADMIN) {
            const firstAvailableAdminPage = pages.find(page => page.startsWith(`${PATH_ADMIN}_`));
            return firstAvailableAdminPage ? getUrlFromPageName(firstAvailableAdminPage) : PATH_CONFIGURATIONS_LIST;
        }
        return getUrlFromPageName(pages[0]);
    }
    return PATH_CONFIGURATIONS_LIST;
}

function getUrlFromPageName(pageName: string) {
    return pageName.split('_').join('/');
}

export default setRoutes;
