import React from 'react';
import Nav from 'modules/nav';

interface IProps {
    toggle: (e: React.MouseEvent<HTMLElement>) => void;
}

const LayoutAside = ({ toggle }: IProps) => {
    return (
        <aside className="layout-aside" onClick={e => toggle(e)}>
            <section className="layout-aside__nav">
                <Nav />
            </section>
        </aside>
    );
};

export default LayoutAside;
