import React, { MouseEvent, RefObject, createRef, useContext, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import cx from 'classnames';
import { Scrollbars } from 'rc-scrollbars';
import AppContext from 'store/context/AppContext';
import ModalContext from 'store/context/ModalContext';
import { setCurrentApp } from 'store/reducers/app';
import { DropdownIndicator } from 'components/select';
import selectStyles from 'components/select/styles';
import { IRoute, ISelectOption, IStore } from 'assets/ts/types';
import getOffsetTop from 'assets/ts/helpers/get-offset-top';
import 'modules/nav/index.scss';

const NO_OPTIONS_MESSAGE = 'Список пуст';

interface INavItemRefs {
    [key: string]: RefObject<HTMLLIElement>;
}

const Nav = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { confForm } = useContext(AppContext);
    const { setData: setModal } = useContext(ModalContext);
    const { app, router } = useSelector((state: IStore) => state);

    const [productsNavFixed, setProductsNavFixed] = useState<boolean>(false);

    const { appsAvailable, currentApp } = app;
    const { routes } = router;

    const appsList = useMemo(() => (appsAvailable || []).map(item => ({ value: item.value, label: item.name })), [appsAvailable]);
    const routesFiltered = useMemo(() => collectRoutes(routes), [routes]);

    const navRef = useRef<HTMLDivElement | null>(null);
    const navItemsRefs = useRef<INavItemRefs>(
        (() => {
            const refs: INavItemRefs = {};
            routesFiltered.forEach(route => {
                refs[`route-${route.id}`] = createRef();
            });
            return refs;
        })()
    );

    const onChangeApp = (option: ISelectOption | null) => {
        const submit = () => {
            dispatch(setCurrentApp(option?.value ?? ''));
        };

        if (confForm.state.isActive && confForm.state.isNew) {
            setModal({
                title: 'Вы изменяете тип оборудования',
                moduleName: 'ConfiguratorFormModal',
                props: {
                    content: (
                        <>
                            <span className="error">Внимание!</span> Изменение типа оборудования приведет к обновлению формы и потере несохранённых
                            данных.
                        </>
                    ),
                    buttonSubmitText: 'Изменить тип оборудования',
                    onSubmit: submit,
                },
            });
        } else {
            submit();
        }
    };

    const onNavigate = (e: MouseEvent<HTMLElement>, route: string) => {
        e.stopPropagation();

        if (confForm.state.isActive) {
            e.preventDefault();

            setModal({
                title: 'Вы покидаете форму конфигуратора',
                moduleName: 'ConfiguratorFormModal',
                props: {
                    content: (
                        <>
                            <span className="error">Внимание!</span> Покинув форму конфигуратора, вы потеряете заполненные данные.
                        </>
                    ),
                    navigateTo: route,
                },
            });
        } else {
            navigate(route);
        }
    };

    const getLink = (route: IRoute, parentRoute: string = '') => {
        if (!route.isActive) return;
        const { id, path, accessible, children, title, element } = route;
        const routeStr = `${parentRoute}/${path}`;

        if (accessible) {
            const isProductsBlock = id === 'products';
            const navChildren = <ul className="nav__list _sub">{children?.map(childRoute => getLink(childRoute, routeStr))}</ul>;

            return (
                <li
                    key={`route-${id}`}
                    className={cx([
                        `nav__list-item _${id}`,
                        {
                            _parent: children?.length,
                            _fixed: isProductsBlock && productsNavFixed,
                            _virtual: element.options?.isVirtual,
                        },
                    ])}
                    ref={navItemsRefs.current[`route-${id}`]}
                >
                    <NavLink
                        to={routeStr}
                        className={({ isActive }) =>
                            cx('nav__list-item-link', [
                                {
                                    _active: isActive && pathname === routeStr,
                                    '_active-parent': isActive && pathname !== routeStr,
                                },
                            ])
                        }
                        onClick={e => onNavigate(e, routeStr)}
                    >
                        {title}
                    </NavLink>
                    {children && (
                        <>{isProductsBlock ? <Scrollbars style={{ height: window.innerHeight - 132 }}>{navChildren}</Scrollbars> : navChildren}</>
                    )}
                </li>
            );
        }
        return '';
    };

    useLayoutEffect(() => {
        if (!navRef?.current) return;
        const { current: productsNavBlock } = navItemsRefs?.current[`route-products`] ?? {};
        if (!productsNavBlock) return;

        const checkPosition = (position: number) => setProductsNavFixed(window.scrollY > position);

        const productsNavBlockPosition = getOffsetTop(productsNavBlock);
        if (!productsNavBlockPosition) return;

        let onScroll = () => checkPosition(productsNavBlockPosition);
        document.addEventListener('scroll', onScroll);

        new ResizeObserver(() => {
            const newProductsNavBlockPosition = getOffsetTop(productsNavBlock);
            if (!newProductsNavBlockPosition) return;

            document.removeEventListener('scroll', onScroll);
            onScroll = () => checkPosition(newProductsNavBlockPosition);
            document.addEventListener('scroll', onScroll);
        }).observe(navRef.current);

        return () => {
            document.removeEventListener('scroll', onScroll);
        };
    }, []);

    return (
        <nav className="nav" ref={navRef}>
            <div className="nav__configurator" onClick={e => e.stopPropagation()}>
                <div className="nav__configurator-container">
                    <Select<ISelectOption>
                        className="nav__select nav__app"
                        classNamePrefix="select"
                        placeholder="Выберите конфигуратор"
                        closeMenuOnSelect={true}
                        onChange={option => onChangeApp(option)}
                        options={appsList}
                        value={appsList.find(i => i.value === currentApp)}
                        components={{
                            DropdownIndicator,
                            IndicatorSeparator: null,
                        }}
                        noOptionsMessage={() => NO_OPTIONS_MESSAGE}
                        styles={selectStyles}
                        isSearchable={false}
                        isDisabled={appsList.length === 1}
                    />
                </div>
            </div>
            {routesFiltered.length && (
                <div className="nav__list-wrapper">
                    <ul className="nav__list">{routesFiltered.map(route => getLink(route))}</ul>
                </div>
            )}
        </nav>
    );
};

function collectRoutes(routes: IRoute[]) {
    let navRoutes: IRoute[] = [];

    const checkRoute = (route: IRoute) => {
        if (route.children) {
            const childrenFiltered: IRoute[] = route.children.filter(item => item.navAside).map(item => checkRoute(item));
            return { ...route, children: childrenFiltered };
        } else {
            return route;
        }
    };

    [...routes].forEach(route => {
        if (route.children) {
            navRoutes = [...navRoutes, ...route.children];
        }
    });

    return navRoutes.filter(route => route.navAside).map(route => checkRoute(route));
}

export default Nav;
