import { createSlice } from '@reduxjs/toolkit';
import { IStateConfigurations } from 'assets/ts/types';

const initialState: IStateConfigurations = {
    filterByCurrentApp: false,
    filterByCurrentUser: false,
    selectedProjectId: null,
};

const configurationsReducer = createSlice({
    name: 'configurations',
    initialState,
    reducers: {
        setFilterByCurrentApp(state, action) {
            return { ...state, filterByCurrentApp: action.payload };
        },
        setFilterByCurrentUser(state, action) {
            return { ...state, filterByCurrentUser: action.payload };
        },
        setSelectedProjectId(state, action) {
            return { ...state, selectedProjectId: action.payload };
        },
    },
});

export const { setFilterByCurrentApp, setFilterByCurrentUser, setSelectedProjectId } = configurationsReducer.actions;
export default configurationsReducer.reducer;
